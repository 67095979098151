<template>
  <div>
    <vs-card>
      <h3>USP Stats</h3>
      <br />
      <div>
        <vs-row vs-w="12">
          <vs-col vs-w="11.9" vs-offset="0.1" class="mt-4">
            <vs-row class="my-6">
              <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
                <p class="filter-heading">Filters</p>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.3" class="macc-tree-drop none_padding"
                id="citys">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns macc-ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                    Team
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="MaccCustomDropDown" style="z-index: 60000;">
                    <treeselect  :disabled="disabled" v-model="init_selected_team" :multiple="true" :options="treeTeam" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="macc-tree-drop none_padding"
                id="citys">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns macc-ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                    City
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="MaccCustomDropDown" style="z-index: 60000;">
                    <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity"
                      :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="macc-tree-drop none_padding"
                id="gm">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns macc-ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                    GM
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="MaccCustomDropDown" style="z-index: 60000;">
                    <treeselect v-model="initSelectedGM" :multiple="true" valueFormat="object"
                      :value-consists-of="'LEAF_PRIORITY'" :options="treeDataGM" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.2" class="macc-tree-drop none_padding"
                id="spoc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns macc-ele-inside-text py-1" href.prevent @click="showTreeBox">
                    SPOC
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="MaccCustomDropDown" style="z-index: 60000">
                    <treeselect v-model="initSelectedSpoc" :value-consists-of="'LEAF_PRIORITY'" valueFormat="object"
                      :multiple="true" :options="treeDataSpoc" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.7" class="macc-tree-drop none_padding"
                id="cm_level">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns macc-ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                    CM Level
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="MaccCustomDropDown" style="z-index: 60000">
                    <treeselect v-model="initSelectedCMLevel" :multiple="true" :options="treeDataCMLevel" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.7" class="macc-tree-drop none_padding"
                id="macc_level">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns macc-ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                    USP Level
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="MaccCustomDropDown" style="z-index: 60000">
                    <treeselect v-model="initSelectedMaccLevel" :multiple="true" :options="treeDataMaccLevel" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>

            </vs-row>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-offset="1.1" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Eligibility Date Range</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                    firstDay: 1,
                    format: 'DD-MMM-YYYY',
                  }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="eligibility" class="macc_date_range">
                  </date-range-picker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-offset="0.2" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Enrollment month and year</label>
                  <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'" class="macc_date_picker"
                    :format="customFormatter" v-model="enrollment_dates" style="position: relative; z-index:1000;">
                  </datepicker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-offset="0.2" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Enrollment Date Range</label>
                  <date-range-picker ref="picker" :opens="'left'" :locale-data="{
                    firstDay: 1,
                    format: 'DD-MMM-YYYY',
                  }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="enrollmentDateRange" class="macc_date_range">
                  </date-range-picker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-offset="0.1" vs-w="1" vs-justify="flex-start" vs-align="center" class="mt-5">
                <vs-button color="#0044BA" icon="cancel" size="small" @click="clearDateRange()"></vs-button> 
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
        <div class="macc_search_criteria mt-3 mb-5 ml-3">
          <div style="display:flex; justify-content: space-between; align-items: center;" class="mt-2 mx-3">
            <h6 class="search_heading ml-2 mt-2">Search Criteria:</h6>
            <div style="display: flex;">
              <vs-button color="#0044BA" icon="refresh" size="small" @click="refreshdata()"></vs-button> &nbsp;
                <vs-button color="#0044BA" icon="search" size="small" @click="getSpocIds()"></vs-button>
            </div>
          </div>
          <div style="display: inline-block; width: 100%; margin-left:10px; padding: 10px;">
            <!-- <span v-for="chip in selected_teams" :key="chip.label">
              <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(234, 229, 250)"
                @click="removechip(chip, 'team')" closable>
                <b>{{ chip }}</b>
              </vs-chip>
            </span> -->
            <div v-if="loggedin_team == 'Admin' || loggedin_team == 'GM'">
                        <span v-for="chip in ExEd_selected_team" :key="chip.label">
                          <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(234, 229, 250)"
                            @click="removechip(chip, 'team')" closable>
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                      </div>
                      <div v-else>
                        <span v-for="chip in ExEd_selected_team" :key="chip.label">
                          <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(234, 229, 250)">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                      </div>
            <span v-for="chip in selected_city" :key="chip.id">
              <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(234, 229, 250)"
                @click="removechip(chip, 'city')" closable>
                <b>{{ chip }}</b>
              </vs-chip>
            </span>
            <span v-for="chip in selected_gm" :key="chip.id">
              <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(202,236,197)"
                @click="removechip(chip, 'gm')" closable>
                <b>{{ chip }}</b>
              </vs-chip>
            </span>
            <span v-for="chip in selected_spoc" :key="chip.id">
              <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(209,241,252)"
                @click="removechip(chip, 'spoc')" closable>
                <b>{{ chip }}</b>
              </vs-chip>
            </span>
            <span v-for="chip in selected_cm" :key="chip.id">
              <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(250,225,254)"
                @click="removechip(chip, 'cm_level')" closable>
                <b>{{ chip }}</b>
              </vs-chip>
            </span>
            <span v-for="chip in selected_macc" :key="chip.id">
              <vs-chip class="macc_criteria_chip" style="color:#000000; background: rgb(255,213,213)"
                @click="removechip(chip, 'macc_level')" closable>

                <b>{{ chip }}</b>
              </vs-chip>
            </span>

          </div>
        </div>
      </div>
      <vs-table :data="macc_summary" max-height="500px">
        <template slot="thead">
          <vs-th :key="index" v-for="(head, index) in table_headers" style="position: sticky; top:0px; background-color: white;z-index: 9;"
          :class="{ fixed_header_columns_section: head == 'INDEX'}">
            <span v-if="head != 'INDEX'">{{ head }}</span>
            <span v-else>Levels</span>
          </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :key="indextd" v-for="(td, indextd) in tr" :class="label_options.includes(td) == true ? 'fixed_columns_section' : ''">
              <div @click="openRawDataPopup(tr.level, indextd, td,1)" > 
                {{ `${td == 'no_level' ? 'No Level' : td}` }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
    <vs-card class="mt-4" v-show="open_raw_data_popup">
      <h4 class="mb-4">{{ popup_heading }}</h4>
      <div v-show="show_data_able">
        <div ref="table5" id="example-table-theme"></div>
        <vs-row style="margin-top: 2%">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
            <vs-pagination :total="total_pages" v-model="current_table_page">
            </vs-pagination>
          </vs-col>
        </vs-row>
      </div>

      <h4 v-show="show_data_able == false" class="mx-6 text-center"> No Data Available</h4>
    </vs-card>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "vue-select/dist/vue-select.css";
import ApiServices from "../ApiServices";
import constants from "../../constants.json"
import DateRangePicker from "vue2-daterange-picker";
import Datepicker from "vuejs-datepicker";
import axios from 'axios';
import moment from "moment";
export default {
  components: {
    Datepicker,
    Treeselect,
    DateRangePicker
  },
  data() {
    return {
      all_users: [],
      cm_users: [],
      sr_users: [],
      label_options :[],
      selected_city: [],
      selected_gm: [],
      selected_spoc: [],
      selected_cm: [],
      selected_teams: [],
      ExEd_selected_team : [],
      selected_macc: [],
      total_pages: 0,
      current_table_page: 1,
      cm_levels: [],
      table_headers: [],
      macc_summary: [],
      macc_raw: [],
      open_raw_data_popup: false,
      show_data_able: true,
      popup_heading: "",
      enrollment_dates: null,
      cm_level: "",
      macc_level: "",
      total_count: 0,
      enrollmentDateRange: {
        startDate: null,
        endDate: null,
      },
      user_with_gms: "",
      initSelectedCity: [],
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedGM: [],
      treeDataGM: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSpoc: [],
      treeDataSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedCMLevel: [],
      treeDataCMLevel: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedMaccLevel: [],
      treeDataMaccLevel: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      init_selected_team: [],
      treeTeam: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "CM", label: "CM" },
            { id: "SR", label: "SR" },
            { id: "ExEd", label: "USP" },
            // { id: "ExEd", label: "MAcc" },
          ]
        }
      ],
      eligibility: {
        startDate: null,
        endDate: null,
      },
      spoc_ids_data: "",
      gm_ids: [],
      default_selected_city: [],
      default_selected_gm:[],
      default_selected_spoc:[],
      loggedin_team: "",
      logged_in_user_id:"",
      login_spoc_id:"",
      disabled: false,
    };
  },
  watch: {
    enrollment_dates(val) {
      if (val != null) {
        this.enrollmentDateRange = {
          startDate: null,
          endDate: null,
        }
      }
    },
    enrollmentDateRange(val) {
      if (val.startDate != null && val.endDate != null) {
        this.enrollment_dates = null;
      }
    },
    current_table_page(val) {
      let start_date = "";
      let end_date = "";
      let enrollment_start_date = "";
        let enrollment_end_date = "";
        let enrollment_month = "";
        let enrollment_year = "";
      if (this.eligibility.startDate != null && this.eligibility.endDate != null) {
        start_date = moment(this.eligibility.startDate).format("YYYY-MM-DD");
        end_date = moment(this.eligibility.endDate).format("YYYY-MM-DD");
      }
      if (this.enrollmentDateRange.startDate != null && this.enrollmentDateRange.endDate != null) {
          enrollment_start_date = moment(this.enrollmentDateRange.startDate).format("YYYY-MM-DD");
          enrollment_end_date = moment(this.enrollmentDateRange.endDate).format("YYYY-MM-DD");
        } 
        if(this.enrollment_dates != null){
          enrollment_month = moment(this.enrollment_dates).format("MM");
          enrollment_year  = moment(this.enrollment_dates).format("YYYY")
        }
      this.openRawDataPopup(this.cm_level, this.macc_level, this.total_count,val, start_date, end_date, this.spoc_ids_data,enrollment_start_date,enrollment_end_date,enrollment_month,enrollment_year);
    },
    open_raw_data_popup(val) {
      if (val == true) {
        this.current_table_page = 1;
      }
    },
    initSelectedCity: function (val) {
      if (val.length != 0) {
        this.mergeAllSearch();
      }else {
        let team = localStorage.getItem('team')
        if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
          this.initSelectedCity = this.default_selected_city
        }
      }
      if(val.length == 0){
        this.selected_city = [];
      }
    },
    initSelectedGM: function (val) {
      if(val.length != 0){
        this.mergeAllSearch();
      }else{
        let team = localStorage.getItem('team')
        if(team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd'){
          this.initSelectedGM = this.default_selected_gm
        }
      }
      if(val.length == 0){
        this.selected_gm = [];
      }
    },
    initSelectedSpoc: function (val) {
      if(val.length != 0){
        this.mergeAllSearch();
      }else{
        let team = localStorage.getItem('team')
        if(team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd'){
          this.initSelectedSpoc = this.default_selected_spoc
        }
      }
      if(val.length == 0){
        this.selected_spoc = [];
      }
    },
    initSelectedCMLevel: function () {
      this.mergeAllSearch();
    },
    initSelectedMaccLevel: function () {
      this.mergeAllSearch();
    },
    init_selected_team: function () {
      this.treeDataSpoc =  [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      this.user_with_gms = [];
      if(this.init_selected_team.includes('CM')){
        this.user_with_gms = this.cm_users;
        this.getDropdownData();
      }
      if(this.init_selected_team.includes('SR')){
        this.user_with_gms = this.sr_users;
        this.getDropdownData();
      }
      if(!this.init_selected_team.includes('SR') && !this.init_selected_team.includes('CM')){
        this.user_with_gms = this.all_users;
        this.getDropdownData();
      }
      this.mergeAllSearch();
    }
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.getUsersWithGMs();
    this.userWiseTeam();
  },

  methods: {
    userWiseTeam(){
      this.logged_in_user_id = localStorage.getItem("user_id");
      this.loggedin_team = localStorage.getItem('team');
      if (this.logged_in_user_id == 2613 || this.logged_in_user_id == 2474){
        this.loggedin_team = 'Admin';
      }
      if(this.loggedin_team == 'Admin' || this.loggedin_team == 'GM'){
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      if (this.loggedin_team === 'CM') {
        this.init_selected_team.push('CM');
      } else if (this.loggedin_team === 'SR') {
        this.init_selected_team.push('SR');
      } else if (this.loggedin_team === 'ExEd') {
        this.init_selected_team.push('ExEd');
      } else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") == 'SR') {
        this.init_selected_team.push('SR');
      } else if (this.logged_in_user_id == 2474) {
        this.init_selected_team.push('SR');
      } 
      // else {
      //   this.init_selected_team.push('CM');
      // }
    },
    refreshdata(){
      this.initSelectedCity = [];
      this.selected_city = [];
      this.initSelectedGM = [];
      this.selected_gm = [];
      this.initSelectedSpoc = [];
      this.selected_spoc = [];
      this.initSelectedCMLevel = [];
      this.initSelectedMaccLevel = [];
      this.init_selected_team = [];
      this.ExEd_selected_team = [];
      this.selected_teams = [];
      this.clearDateRange();
      this.getUsersWithGMs();
       this.userWiseTeam();
    },
    mergeAllSearch() {
      this.selected_teams = [];
      this.ExEd_selected_team = []
      this.init_selected_team.forEach((sort) => {
        if (sort === "All") {
          this.treeTeam[0].children.forEach((child) => {
            if(child.label == "ExEd"){
              this.selected_teams.push("ExEd");
              this.ExEd_selected_team.push("USP");
            }else{
              this.selected_teams.push(child.label);
              this.ExEd_selected_team.push(child.label)
            }
            
          });
        } else {
          if(sort == "ExEd"){
            this.selected_teams.push("ExEd");
            this.ExEd_selected_team.push("USP");
          }else{
            this.selected_teams.push(sort);
            this.ExEd_selected_team.push(sort)
          }
         
        }

      });
      this.selected_city = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            this.selected_city.push(child.label);
          });
        } else {
          this.selected_city.push(sort.label);
        }
      });
      this.selected_gm = [];
      this.initSelectedGM.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataGM[0].children.forEach((child) => {
            this.selected_gm.push(child.label);
          });
        } else {
          this.selected_gm.push(sort.label);
        }
      });
      this.selected_spoc = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataSpoc[0].children.forEach((child) => {
            this.selected_spoc.push(child.label);
          });
        } else {
          this.selected_spoc.push(sort.label);
        }
      });
      this.selected_cm = [];
      this.initSelectedCMLevel.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCMLevel[0].children.forEach((child) => {
            this.selected_cm.push(child.label);
          });
        } else {
          this.selected_cm.push(sort);
        }
      });
      this.selected_macc = [];
      this.initSelectedMaccLevel.forEach((sort) => {
        if (sort === "All") {
          this.treeDataMaccLevel[0].children.forEach((child) => {
            this.selected_macc.push(child.label);
          });
        } else {
          this.selected_macc.push(sort);
        }
      });
    },
    getUsersWithGMs() {
      let url = `${constants.SERVER_API}getUsersWithGMs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          let logged_in_user = localStorage.getItem('user_id')
          let team = localStorage.getItem('team')
          if (team == 'CM' || team == 'SR' || team == 'ExEd') {
            response.data.forEach(ele => {
              if (logged_in_user == ele.id) {
                this.user_with_gms = []
                this.user_with_gms.push(ele);
                this.getDropdownData();
              }
            });
          } else if (localStorage.getItem('team') == 'GM') {
            this.getGmReportingSpocs(response.data)
          } else {
            // this.user_with_gms = response.data
            // this.getDropdownData();
            this.sr_users = [];
            this.cm_users = [];
            this.all_users = [];
            this.user_with_gms = [];
            for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i];
                if(element.team == 'CM'){
                  this.cm_users.push(element);
                }
                if(element.team == 'SR'){
                  this.sr_users.push(element);
                } 
                this.all_users.push(element);
                
            }
            this.user_with_gms = [];
            if(this.init_selected_team.includes('CM')){
              this.user_with_gms = this.cm_users;
              this.getDropdownData();
            }
            if(this.init_selected_team.includes('SR')){
              this.user_with_gms = this.sr_users;
              this.getDropdownData();
            }
            if(!this.init_selected_team.includes('SR') && !this.init_selected_team.includes('CM')){
              this.user_with_gms = this.all_users;
              this.getDropdownData();
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        }).finally(()=>{
          this.getSpocIds();
        })
    },
    getGmReportingSpocs(res) {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            ids.push(element.id);
          }
          this.gm_ids = ids
          this.user_with_gms = []
          this.gm_ids.forEach(gm => {
            res.forEach(ele => {
              if (ele.id == gm) {
                this.user_with_gms.push(ele);
              }
            });
          });
          this.getDropdownData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getDropdownData() {
      this.initSelectedCity = [];
      this.initSelectedGM = [];
      this.initSelectedSpoc = [];
      this.treeDataCity = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ]
      let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      uniqe_city.forEach(element => {
        let obj = {
          id: element,
          label: element,
          children: []
        }
        if(city_options.includes(element)){
          this.treeDataCity[0].children.push(obj);
        }
      })
      this.user_with_gms.forEach((element) => {
        this.treeDataCity[0].children.forEach((city) => {
          if (element.city === city.label) {
            let cc_set = new Set(city.children.map((item) => item.label));
            // if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
            //   let cc_obj = {
            //     id: element.city + "_" + element.city_classification,
            //     label: element.city_classification
            //   }
            //   city.children.push(cc_obj);
            //   let team = localStorage.getItem('team')
            //   if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
            //     this.initSelectedCity.push(cc_obj)
            //   }
            //   this.default_selected_city = this.initSelectedCity;
            // }
            if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                        let city_classifaication = element.city_classification
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let cc_obj = {
                            id: element.city + "_" + element.city_classification,
                            label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelectedCity;
                        }else{
                        if(city_classifaication.substring(0,1) != 'X'){
                            let cc_obj = {
                                id: element.city + "_" + element.city_classification,
                                label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelectedCity;
                        }
                        }
                 }
          }
        })
      });
      this.treeDataGM = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      uniqe_city.forEach(element => {
        let obj = {
          id: element,
          label: element,
          children: []
        }
        this.treeDataGM[0].children.push(obj);
      })
      this.user_with_gms.forEach((element) => {
        this.treeDataGM[0].children.forEach((city) => {
          if (element.city === city.label) {
            if (element.gm !== "No GM") {
              let gm_set = new Set(city.children.map((item) => item.label));
              // if (!gm_set.has(element.gm)) {
              //   let gm_obj = {
              //     id: element.city + "_" + element.gm,
              //     label: element.gm
              //   }
              //   city.children.push(gm_obj);
              //   let team = localStorage.getItem('team')
              //   if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
              //     this.initSelectedGM.push(gm_obj)
              //   }
              //   this.default_selected_gm = this.initSelectedGM
              // }
              if (!gm_set.has(element.gm)) {
                        let gm_data = element.gm
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let gm_obj = {
                            id: element.city + "_" + element.gm,
                            label: element.gm
                            }
                            city.children.push(gm_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(gm_obj)
                            }
                            this.default_selected_gm = this.initSelectedGM;
                        }else{
                        if(gm_data.substring(0,1) != 'X'){
                            let gm_obj = {
                                id:element.city + "_" + element.gm,
                                label: element.gm
                            }
                            city.children.push(gm_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(gm_obj)
                            }
                            this.default_selected_gm = this.initSelectedGM;
                        }
                        }
                 }
            }
          }
        })
      });

      uniqe_city.forEach(element => {
        let obj = {
          id: element,
          label: element,
          children: []
        }
        this.treeDataSpoc[0].children.push(obj);
      })
      this.user_with_gms.forEach((element) => {
        this.treeDataSpoc[0].children.forEach((city) => {
          if (element.city === city.label) {
            let gm_set = new Set(city.children.map((item) => item.label));
            // if (!gm_set.has(element.last_name)) {
            //   let spoc_obj = {
            //     id: element.city + "_" + element.first_name + " " + element.last_name,
            //     label: element.first_name + " " + element.last_name
            //   }
            //   city.children.push(spoc_obj);
            //   let team = localStorage.getItem('team')
            //     if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
            //       this.initSelectedSpoc.push(spoc_obj)
            //     }
            //     this.default_selected_spoc = this.initSelectedSpoc
            // }
            if (!gm_set.has(element.last_name)) {
                        let name = element.first_name
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let spoc_obj = {
                            id: element.city + "_" + element.first_name + " " + element.last_name,
                            label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                        this.default_selected_spoc = this.initSelectedSpoc

                        }else{
                        if(name.substring(0,1) != 'X'){
                            let spoc_obj = {
                                id: element.city + "_" + element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                          this.default_selected_spoc = this.initSelectedSpoc

                        }
                        }
                    } 
          }
        })
      });
    },
    openPopupEmit(mwb_id) {
      this.openBigPopup(mwb_id);
    },
    openRawDataPopup(cm_level, macc_level, total_count,page) {
      if (total_count != 0) {
        this.$vs.loading();
        this.cm_level = cm_level;
        this.macc_level = macc_level;
        this.total_count = total_count;
        this.show_data_able = true;
        this.macc_raw = [];
        this.popup_heading = "Total Count" + ": " + total_count + "|" + "CM Level" + ":" + cm_level + " " + "|" + " " + "USP Level" + ":" + macc_level;
        let encoded_macc_level = encodeURIComponent(macc_level);
        let encoded_cm_level = encodeURIComponent(cm_level);
        let start_date = "";
        let end_date = "";
        let enrollment_start_date = "";
        let enrollment_end_date = "";
        let enrollment_month = "";
        let enrollment_year = "";
        if (this.eligibility.startDate != null && this.eligibility.endDate != null) {
          start_date = moment(this.eligibility.startDate).format("YYYY-MM-DD");
          end_date = moment(this.eligibility.endDate).format("YYYY-MM-DD");
        }
        if (this.enrollmentDateRange.startDate != null && this.enrollmentDateRange.endDate != null) {
          enrollment_start_date = moment(this.enrollmentDateRange.startDate).format("YYYY-MM-DD");
          enrollment_end_date = moment(this.enrollmentDateRange.endDate).format("YYYY-MM-DD");
        } 
        if(this.enrollment_dates != null){
          enrollment_month = moment(this.enrollment_dates).format("MM");
          enrollment_year  = moment(this.enrollment_dates).format("YYYY")
        }
        let selected_team = [];
        if(this.init_selected_team.includes('All')){
          selected_team = [''];
        } else {
          selected_team = this.init_selected_team;
        }
        ApiServices.maccCmLevelRaw(encoded_cm_level, encoded_macc_level, page, total_count, start_date, end_date, this.spoc_ids_data,enrollment_start_date,enrollment_end_date,enrollment_month,enrollment_year,selected_team)
          .then((response) => {
            this.macc_raw = response.data.data;

            if (this.macc_raw.length == 0) {
              this.show_data_able = false
            } else {
              this.show_data_able = true
            }
            this.total_pages = response.data.last_page;
            this.current_table_page = response.data.current_page;

            this.getMaccCountDetails();
            this.open_raw_data_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    maccCmLevelSummary() {
      this.treeDataCMLevel[0].children = [];
      this.treeDataMaccLevel[0].children = [];
      this.$vs.loading();
      let start_date = "";
      let end_date = "";
      let enrollment_start_date = "";
      let enrollment_end_date = "";
      let enrollment_month = "";
      let enrollment_year = "";
      if (this.eligibility.startDate != null && this.eligibility.endDate != null) {
        start_date = moment(this.eligibility.startDate).format("YYYY-MM-DD");
        end_date = moment(this.eligibility.endDate).format("YYYY-MM-DD");
      }
      if (this.enrollmentDateRange.startDate != null && this.enrollmentDateRange.endDate != null) {
        enrollment_start_date = moment(this.enrollmentDateRange.startDate).format("YYYY-MM-DD");
        enrollment_end_date = moment(this.enrollmentDateRange.endDate).format("YYYY-MM-DD");
      }
      if (this.initSelectedCMLevel[0] == "All") {
        this.initSelectedCMLevel = [];
      }
      if (this.initSelectedMaccLevel[0] == "All") {
        this.initSelectedMaccLevel = [];
      }
      if(this.enrollment_dates != null){
        enrollment_month = moment(this.enrollment_dates).format("MM");
        enrollment_year  = moment(this.enrollment_dates).format("YYYY")
      }
      let obj = {
        cm_level: this.selected_cm.join(),
        macc_level: this.selected_macc.join(),
        eligibility_from_date: start_date,
        eligibility_to_date: end_date,
        spoc_id: '',
        sr_id: '',
        enrollment_from_date: enrollment_start_date,
        enrollment_to_date: enrollment_end_date,
        enrollment_month: enrollment_month,
        enrollment_year: enrollment_year,
        team: '',
      }
         
      // let selected_team = [];
      if(this.init_selected_team.includes('All')){
        obj.team = '';
      } else {
        obj.team = this.init_selected_team.join();
      }
      if(this.init_selected_team.includes('SR')){
        obj.sr_id = this.spoc_ids_data;
      }else {
        obj.spoc_id = this.spoc_ids_data;
      }
      // if(obj.team == 'CM' || obj.team == 'SR'){
      //   obj.spoc_id = '';
      // }
      // let url = `${constants.MILES_GOOGLE_INVITE}maccCmLevelSummary`;
      // axios
      //   .get(url, {
      //     params: obj,
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
      ApiServices.maccCmLevelSummary(obj)
        .then((response) => {
          this.$vs.loading.close();
          this.macc_summary = response.data;
          // let cm_team_arr = [];
          // let sr_team_arr = [];
          // let cm_levels_arr = ['M1','M2', 'M3', 'M3+', 'M3++', 'M4-', 'M4', 'M5', 'M6'];
          // let sr_levels_arr = ['M7', 'M7-', 'M7+', 'M7#', 'M8', 'M8+', 'M8-', 'M9', 'M9-', 'M10', 'M7-JJ', 'M7X', 'M7D1', 'M7D2', 'M7-IR', 'M7-INT'];
          // response.data.forEach(ele => {
          //   let lvl = ele.index;
          //   if(cm_levels_arr.includes(lvl) ){
          //     cm_team_arr.push(ele);
          //   }else if(sr_levels_arr.includes(lvl)){
          //     sr_team_arr.push(ele)
          //   }
          // });
          // if (this.init_selected_team.includes('CM') && this.init_selected_team.includes('SR')) {
          //   this.macc_summary = response.data;
          // } 
          // else if (this.init_selected_team.includes('CM')) {
          //   this.macc_summary = cm_team_arr;
          // } else if (this.init_selected_team.includes('SR')) {
          //   this.macc_summary = sr_team_arr;
          // }
          //  else {
          //   this.macc_summary = response.data;
          // }
          this.cm_levels = [
            ...new Set(this.macc_summary.map((item) => item.level)),
          ];
          this.cm_levels.forEach((level) => {
            if (level != 'Total') {
              let cm_level_obj = {
                id: level,
                label: level
              }
              this.treeDataCMLevel[0].children.push(cm_level_obj)
            }

          })
          this.label_options = this.cm_levels;
          this.macc_summary.forEach((element) => {
            this.table_headers = Object.keys(element).map(key => key.toUpperCase());
          });
          this.table_headers.forEach((level) => {
            if (level != 'Total' && level != 'level' && level != 'INDEX') {
              let macc_level_obj = {
                id: level,
                label: level
              }
              this.treeDataMaccLevel[0].children.push(macc_level_obj)
            }

          })
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);

        });
    },
    getMaccCountDetails() {
      this.macc_raw.forEach(element => {
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      })
      this.tabulator = new Tabulator(this.$refs.table5, {
        maxHeight: "80vh",
        data: this.macc_raw,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
        columns: [
          {
            title: "Can-ID",
            field: "can_id",
            frozen: true,
            sorter: "alphanum",
          },
          {
            title: "Candidate Name",
            field: "can_name",
            frozen: true,
          },
          {
            title: "CM Level",
            field: "cm_level",
          },
          {
            title: "USP Level",
            field: "macc_level",
          },
          {
            title: "CM SPOC",
            field: "cm_spoc",
          },
          {
            title: "USP SPOC",
            field: "macc_spoc",
          },
          {
            title: "Enrollment Date",
            field: "enrollment_date",
          },
          {
            title: "Company",
            field: "company",
          },
          {
            title: "Education",
            field: "education",
          },
          {
            title: "Experience",
            field: "experience",
          },
          {
            title: "First Source",
            field: "first_source",
          },
          {
            title: "Comments",
            field: "comments",
          },
          {
            title: "About",
            field: "info",
            formatter: "image",
            hozAlign: "center",
            frozen: true,
            headerSort:false,
            formatterParams: {
              height: "25px",
              width: "25px",
            },
            cellClick: this.getId,
          },
        ],
      });
    },
    getId(e, cell) {
      this.openBigPopup(cell._cell.row.data.mwb_id);
    },
    removechip(chip, name) {
      let team = localStorage.getItem('team')
      if (name == "team") {
        if (this.init_selected_team.indexOf(chip) === -1) {
          this.init_selected_team = [];
          this.treeTeam[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.init_selected_team.push(child.label);
              this.selected_teams.push(child.label);
            }
          });
        } else {
          this.init_selected_team.splice(this.init_selected_team.indexOf(chip), 1);
          this.selected_teams.splice(this.selected_teams.indexOf(chip), 1);
        }
      }
      if (name == "city" && team != 'CM' && team != 'SR' && team != 'GM' && team != 'ExEd') {
        for (let i = 0; i < this.initSelectedCity.length; i++) {
          if (this.initSelectedCity[i].label === chip) {
            this.initSelectedCity.splice(i, 1); // Remove the matching object
            this.selected_city.splice(i, 1);
            break; // Exit the loop after removing the first match
          }
        }
      }
      if (name == "gm") {
        for (let i = 0; i < this.initSelectedGM.length; i++) {
          if (this.initSelectedGM[i].label === chip) {
            this.initSelectedGM.splice(i, 1);
            this.selected_gm.splice(i, 1);
            break;
          }
        }
      }
      if (name == "spoc") {
        for (let i = 0; i < this.initSelectedSpoc.length; i++) {
          if (this.initSelectedSpoc[i].label === chip) {
            this.initSelectedSpoc.splice(i, 1);
            this.selected_spoc.splice(i, 1);
            break;
          }
        }
      }
      if (name == "cm_level") {
        if (this.initSelectedCMLevel.indexOf(chip) === -1) {
          this.initSelectedCMLevel = [];
          this.treeDataCMLevel[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCMLevel.push(child.label);
              this.selected_cm.push(child.label);
            }
          });
        } else {
          this.initSelectedCMLevel.splice(this.initSelectedCMLevel.indexOf(chip), 1);
          this.selected_cm.splice(this.selected_cm.indexOf(chip), 1);
        }
      }
      if (name == "macc_level") {
        if (this.initSelectedMaccLevel.indexOf(chip) === -1) {
          this.initSelectedMaccLevel = [];
          this.treeDataMaccLevel[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedMaccLevel.push(child.label);
              this.selected_macc.push(child.label);
            }
          });
        } else {
          this.initSelectedMaccLevel.splice(this.initSelectedMaccLevel.indexOf(chip), 1);
          this.selected_macc.splice(this.selected_macc.indexOf(chip), 1);
        }
      }
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
    getSpocIds() {
      let city = [];
      let cc = [];
      let uniqe_city = [];
      let uniqe_gm = [];
      let uniqe_spoc = [];
      if (this.initSelectedCity.length != 0) {
        this.initSelectedCity.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(CC => {
              cc.push(CC.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            cc.push(item.label)
          }
        });

        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if (this.initSelectedGM.length != 0) {
        this.initSelectedGM.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_gm.push(data.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            uniqe_gm.push(item.label)
          }
        });
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if (this.initSelectedSpoc.length != 0) {
       
        this.initSelectedSpoc.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_spoc.push(data.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            uniqe_spoc.push(item.label)
          }
        });
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      let spoc_ids = [];
      this.user_with_gms.forEach(raw => {
        if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            if(raw.team == team){
              spoc_ids.push(raw.id);
            }
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0){
          uniqe_city.forEach(city => {
            cc.forEach(cc => {
              if (raw.city == city && raw.city_classification == cc) {
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
          uniqe_gm.forEach(gm => {
            if(raw.gm == gm){
              spoc_ids.push(raw.id);
            }
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
          uniqe_spoc.forEach(spoc => {
            if(raw.first_name + " " + raw.last_name == spoc){
              spoc_ids.push(raw.id);
            }
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              if(raw.team == team && raw.city == city && raw.city_classification == cc){
                spoc_ids.push(raw.id);
              }
            })
          })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
              if(raw.city == city && raw.city_classification == cc && raw.gm == gm){
                spoc_ids.push(raw.id);
              }
            })
          })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
                uniqe_spoc.forEach(spoc => {
              if(raw.city == city && raw.city_classification == cc && raw.first_name + " " + raw.last_name == spoc){
                spoc_ids.push(raw.id);
              }
            })
          })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          uniqe_gm.forEach(gm => {
            uniqe_spoc.forEach(spoc => {
              if(raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
          this.selected_teams.forEach(team => {
            uniqe_spoc.forEach(spoc => {
              if(raw.team == team && raw.first_name + " " + raw.last_name == spoc){
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            uniqe_gm.forEach(gm => {
              if(raw.team == team && raw.gm == gm){
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
                if(raw.team == team && raw.city == city && raw.city_classification == cc && raw.gm == gm){
                  spoc_ids.push(raw.id);
                }
              })
            })
            })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          uniqe_city.forEach(city => {
            cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
                uniqe_spoc.forEach(spoc => {
                if(raw.city == city && raw.city_classification == cc && raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
          })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          this.selected_teams.forEach(team => {
              uniqe_gm.forEach(gm => {
                uniqe_spoc.forEach(spoc => {
                if(raw.team == team && raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
          this.selected_teams.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
                uniqe_spoc.forEach(spoc => {
                if(raw.team == team && raw.city == city && raw.city_classification == cc && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          this.init_selected_team.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
                uniqe_spoc.forEach(spoc => {
                if(raw.team == team && raw.city == city && raw.city_classification == cc && raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
            })
            })
          })
        } 

      //  else if (this.init_selected_team.length != 0 && this.initSelectedCity.length != 0) {
      //     uniqe_city.forEach(city => {
      //       this.init_selected_team.forEach(team => {
      //         cc.forEach(cc => {
      //           if (raw.city == city && raw.city_classification == cc && raw.team == team) {
      //             spoc_ids.push(raw.id);
      //           }
      //         })
      //       })

      //     })
      //   }
      //   if (this.initSelectedGM.length != 0) {
      //     uniqe_city.forEach(city => {
      //       uniqe_gm.forEach(gm => {
      //         if (raw.city == city && raw.gm == gm) {
      //           spoc_ids.push(raw.id);
      //         }
      //       })
      //     })
      //   }
      //   if (this.initSelectedSpoc.length != 0) {
      //     uniqe_city.forEach(city => {
      //       uniqe_spoc.forEach(spoc => {
      //         if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
      //           spoc_ids.push(raw.id);
      //         }
      //       })
      //     })
      //   } 

      });
      spoc_ids = [...new Set(spoc_ids.map((data) => data))];
      this.spoc_ids_data = spoc_ids.join();
      this.open_raw_data_popup = false;
      this.maccCmLevelSummary();
    },
    clearDateRange() {
      this.eligibility = {
        startDate: null,
        endDate: null,
      }
      this.enrollment_dates = null;
      this.enrollmentDateRange = {
        startDate: null,
        endDate: null,
      }
    }

  },
};
</script>

<style>
.search-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  min-height: 450.69px;
}

.none_padding {
  padding: 0px;
}

.macc-tree-drop#citys>.vs-con-dropdown {
  background: rgb(234, 229, 250);
  border: 1px solid rgba(46, 13, 146, 0.5);
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.macc-tree-drop#gm>.vs-con-dropdown {
  background: rgb(202, 236, 197);
  border: 1px solid #a3d79b;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.macc-tree-drop#spoc>.vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #1fabd8;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.macc-tree-drop#cm_level>.vs-con-dropdown {
  background: rgb(250, 225, 254);
  border: 1px solid #de56f4;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.macc-tree-drop#macc_level>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.macc-tree-drop#Visitor>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.macc-tree-drop#level>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.macc-tree-drop#status>.vs-con-dropdown {
  background: #F5E1CE;
  border: 1px solid #F1A964;
  border-radius: 10px;
  width: 100%;
}

.macc-tree-drop#type>.vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #F38630;
  border-radius: 10px;
  width: 100%;
}

.macc-tree-drop#level_drop>.vs-con-dropdown {
  background: #99eee3;
  border: 1px solid #14d685;
  border-radius: 10px;
  width: 100%;
}

.macc-tree-drop>.vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.alignment {
  text-align: initial;
}

.macc-ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.macc-ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.a-iconns>.material-icons {
  margin-left: auto;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.a-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.visitor_dropdown .vs__dropdown-toggle {
  height: 45px !important;
}

.filter-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.visitor_dropdown .vs__dropdown-toggle .vs__selected-options span {
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
}

.MaccCustomDropDown {
  width: 300px;
}

.date_filter_heading {
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.macc_date_range>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba !important;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

@media only screen and (max-width: 1450px) {
  .date_filter_heading {
    font-size: 9px
  }
}

.tabulator_card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.tabulator {
  background-color: #f2f2f2;
}

.tabulator .tabulator-header {
  background-color: #f2f2f2;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}
.fixed_columns_section {
    left: 0px;
    position: sticky !important;
    z-index: 99 !important;
    background: white;
}
.fixed_header_columns_section{
    left: 0px;
    top:0px;
    position: sticky !important;
    z-index: 99 !important;
    background: rgb(160, 45, 45);
}
.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}

.macc_search_criteria {
  width: 99%;
  background: #ffffff;
  min-height: 110px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.search_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.macc_criteria_chip {
  border-radius: 5px;
  font-size: 8px;
}

.macc_criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
  font-size: 10px;
}

.macc_date_picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  min-height: 44.47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

</style>